import request from '@/plugin/axios'
/*
* 分页查询*
*/
export function fetchList (query) {
  return request({
    url: '/tms/tmszz/page',
    method: 'post',
    data: query
  })
}
/*
* 新增中转单主表*
*/
export function addObj (obj) {
  return request({
    url: '/tms/tmszz',
    method: 'post',
    data: obj
  })
}
/*
* 通过id查询*
*/
export function getObj (id) {
  return request({
    url: '/tms/tmszz/' + id,
    method: 'get'
  })
}
/*
*通过id删除中转单主表*
*/
export function delObj (id) {
  return request({
    url: '/tms/tmszz/' + id,
    method: 'delete'
  })
}
/*
*修改中转单主表*
*/
export function putObj (obj) {
  return request({
    url: '/tms/tmszz',
    method: 'put',
    data: obj
  })
}
/**
 * 通过ids批量删除运单
 */
export function zzdelObjs (ids) {
  return request({
    url: '/tms/tmszz/delzzids',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单审单
 */
export function zzAudit (ids) {
  return request({
    url: '/tms/tmszz/audit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单反审单
 */
export function zzUnaudit (ids) {
  return request({
    url: '/tms/tmszz/unaudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单审核
 */
export function zzExamine (ids) {
  return request({
    url: '/tms/tmszz/examine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单反审核
 */
export function zzUnexamine (ids) {
  return request({
    url: '/tms/tmszz/unexamine',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单复审
 */
export function zzReexamine (ids) {
  return request({
    url: '/tms/tmszz/retrial',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单反复审
 */
export function zzUnReexamine (ids) {
  return request({
    url: '/tms/tmszz/unretrial',
    method: 'post',
    params: ids
  })
}
/*
* 中转单详情接口*
*/
export function getZzDetails (id) {
  return request({
    url: '/tms/tmszz/zzdetails/' + id,
    method: 'get'
  })
}
/**
 * 中转单更新接口
 */
export function zzupdateZZ (obj) {
  return request({
    url: '/tms/tmszz/updatezz',
    method: 'post',
    data: obj
  })
}
/**
 * 新增中转单保存
 */
export function zzdsavaZZ (obj) {
  return request({
    url: '/tms/tmszz/savezz',
    method: 'post',
    data: obj
  })
}
/*
* 通过id查询*
*/
export function zzdedit (id) {
  return request({
    url: '/tms/tmszz/zzedit/' + id,
    method: 'get'
  })
}
/**
 * 上传阿里 中转单保存到后台
 */
export function uploadAliZzd (obj) {
  return request({
    url: '/tms/ossobject/zzd',
    method: 'post',
    data: obj
  })
}

/**
 * 价格体系计算
 */
export function zzLogictPrice (obj) {
  return request({
    url: '/tms/tmszz/price',
    method: 'post',
    data: obj
  })
}
/*
* 通过托运单ID查询*
*/
export function getZzListByTydId (ydNo) {
  return request({
    url: '/tms/tmszz/yddetails',
    method: 'get',
    params: ydNo
  })
}
/**
 * 通过ids、sendType短信模板预览界面接口
 */
export function getMessage (obj) {
  return request({
    url: '/tms/smshistory/template',
    method: 'post',
    params: obj
  })
}
/**
 * 单条短信发送接口
 */
export function sendMessage (obj) {
  return request({
    url: '/tms/smshistory/send',
    method: 'post',
    params: obj
  })
}
/**
 * 批量推送
 */
export function batchPush (ids) {
  return request({
    url: '/tms/tmszz/pushAll',
    method: 'post',
    params: ids
  })
}
/**
 * 撤销推送
 */
export function delPush (ids) {
  return request({
    url: '/tms/tmszz/pushDel',
    method: 'post',
    params: ids
  })
}
/*
* 通过contractNo查看合同*
*/
export function getEleContract (contractNo) {
  return request({
    url: '/tms/tmszz/contract',
    method: 'get',
    params: contractNo
  })
}
/*
* 通过id 签订电子合同*
*/
export function signEleContract (id) {
  return request({
    url: '/tms/tmszz/pushHyb',
    method: 'get',
    params: { id: id }
  })
}
/*
* 通过id 取消电子合同*
*/
export function cancelEleContract (id) {
  return request({
    url: '/tms/tmszz/cancelContract',
    method: 'get',
    params: { zzId: id }
  })
}
/**
 * 上传阿里 中转单纸质合同保存到后台
 */
export function uploadAliDriverContract (obj) {
  return request({
    url: '/tms/ossobject/driverContract',
    method: 'post',
    data: obj
  })
}
/*
*修改收款信息*
*/
export function updateBankInfo (obj) {
  return request({
    url: '/tms/tmszz/updateBankInfo',
    method: 'post',
    data: obj
  })
}
/*
*批量修改收款信息*
*/
export function updateBankInfoBatch (obj) {
  return request({
    url: '/tms/tmszz/updateBankInfoBatch',
    method: 'post',
    params: obj
  })
}
/*
* 刷新Token*
*/
export function refreshToken () {
  return request({
    url: '/tms/tmszz/refreshAccessToken',
    method: 'get'
  })
}
/**
 * 通过ids进行中转单 冻结
 */
export function zzFrozen (obj) {
  return request({
    url: '/tms/tmszz/tmsAutomaticFreezing',
    method: 'post',
    params: obj
  })
}
/**
 * 通过ids进行中转单 解冻
 */
export function zzUnFrozen (ids) {
  return request({
    url: '/tms/tmszz/tmsThawFreezing',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单签收单审核
 */
export function zzSignExamine (ids) {
  return request({
    url: '/tms/tmszz/signAudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids进行中转单签收单反审核
 */
export function zzUnSignExamine (ids) {
  return request({
    url: '/tms/tmszz/signAntiAudit',
    method: 'post',
    params: ids
  })
}
/**
 * 通过ids生成费用异动
 */
export function generateTransaction (ids) {
  return request({
    url: '/tms/tmszz/batchGenerateChange',
    method: 'post',
    params: ids
  })
}
/*
* 计算扣保金额*
*/
export function computeInsure (logisticId, senderId, zzAmount, ydAmount) {
  return request({
    url: '/tms/tmszz/insurance',
    method: 'get',
    params: { logisticId: logisticId, senderId: senderId, zzAmount: zzAmount, ydAmount: ydAmount }
  })
}
/**
 * 获取城市之间公里数
 */
export function getCityDistance (ids) {
  return request({
    url: '/tms/tmszz/getCityDistance',
    method: 'post',
    params: ids
  })
}

export function fcOnstatus (obj) {
  return request({
    url: '/tms/tmszz/fc',
    method: 'post',
    data: obj
  })
}
export function unFcOnstatus (obj) {
  return request({
    url: '/tms/tmszz/unfc',
    method: 'post',
    data: obj
  })
}
